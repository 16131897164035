import React from "react"
import Layout from "../components/layout"
import ImgLine from "../images/modal-line.png";
import { useForm } from "react-hook-form";
import SEO from "../components/seo";

export default function Auth(props) {

    const { register, errors, formState } = useForm({
        mode: "all",
        reValidateMode: "onChange",
    });

    const { isValid } = formState

    return (
        <Layout location={props.location}>
            <SEO title="Вход в личный кабинет" />
             <div className="register-box">
                 <div className="register__head">
                     <h2>Вход в личный кабинет</h2>
                     <img className="register__head_img" src={ImgLine} alt="" />
                 </div>
                 <form method="GET" className="register-form" action="https://2.r-lab.biz/LogIn.aspx">
                     <div className="register-form__field">
                         <input
                             name="loginemail"
                             type="email"
                             className="register-form__input"
                             id="auth_email"
                             placeholder="Email"
                             ref={register({
                                 required: "Необходимо заполнить",
                                 pattern: {
                                     value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                                     message: "Неверный формат email адреса",
                                 },
                             })}
                         />
                         <div className="formErrors">
                             <p className="register-form__error">
                                 {errors.loginemail?.message}
                             </p>
                         </div>
                     </div>
                     <div className="register-form__field">
                         <input
                             name="loginpw"
                             type="password"
                             className="register-form__input"
                             id="auth_password"
                             placeholder="Пароль"
                             ref={register({
                                 required: "Необходимо заполнить",
                                 minLength: {
                                     value: 6,
                                     message: "Минимальная длина поля 6 символов."
                                 }
                             })}
                         />
                         <div className="formErrors">
                             <p className="register-form__error">
                                 {errors.loginpw?.message}
                             </p>
                         </div>
                     </div>
                     <button
                         type="submit"
                         className="btn-overall register-form__btn auth_btn"
                         disabled={!isValid}
                     >
                         Войти
                     </button>
                     <div className="auth-form__flex">
                         <a
                             className="auth-form__link"
                             href="https://2.r-lab.biz/Recovery.aspx"
                             target="_blank"
                             rel="noopener noreferrer"
                         >
                             Забыли пароль?
                         </a>
                     </div>
                 </form>
             </div>
        </Layout>
    )
}
